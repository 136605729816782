import { Button, Modal, Table } from '@themesberg/react-bootstrap';
import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { toast } from 'react-toastify';
import { bulkVote } from '../../service.js/service';

export default function ConfirmVotesModal({ onClose, showModal, positions }) {
  const { votes, removeVote, user } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleVote = async () => {
    try {
      setLoading(true);

      // Check if all required positions are voted on
      const votedPositions = votes.map((vote) => vote.position);
      const missingVotes = positions.filter(
        (position) => !votedPositions.includes(position.name)
      );

      const srcVotes = votes.filter(
        (v) => v.position === 'STUDENTS REPRESENTATIVE COUNCIL (SRC)'
      );

      if (missingVotes.length > 0) {
        toast.error(
          `You must vote for all positions. Missing votes for: ${missingVotes
            .map((pos) => pos.name)
            .join(', ')}`,
          {
            position: 'top-right',
            autoClose: 4000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
        return;
      }

      if (srcVotes.length !== 5) {
        toast.error('You must vote 5 SRC!!!', {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      if (!votes || votes.length === 0) {
        toast.error('Select candidates to submit your votes', {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }

      await bulkVote(votes);
      toast.success('Votes submitted', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      localStorage.setItem('ACCESS_USER', null);
      document.location.href = '/';
    } catch (error) {
      console.error('Error voting:', error);
      toast.error(error.message, {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <Modal
      as={Modal.Dialog}
      onHide={onClose}
      centered
      show={showModal}
      style={{ minWidth: '80% !important' }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="h6">Confirm Votes</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table hover responsive className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom"></th>
              <th className="border-bottom">Position</th>
              <th className="border-bottom">Name</th>
            </tr>
          </thead>
          <tbody>
            {votes.map((vote) => (
              <tr key={vote.candidate}>
                <td>
                  <img
                    alt="img"
                    src={vote.filename}
                    style={{ maxWidth: 150, maxHeight: 70 }}
                  />
                </td>
                <td>{vote.position}</td>
                <td>{vote.name}</td>
                <td>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => {
                      removeVote(vote.position, vote.candidate);
                    }}
                  >
                    Remove vote
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>

      <Modal.Footer className="justify-content-center">
        <Button className="w-90" onClick={handleVote} disabled={loading}>
          {loading ? 'Submitting...' : 'Submit Votes'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
