import React, { useState, useEffect } from 'react';
import {
  Table,
  Card,
} from '@themesberg/react-bootstrap';
import {
  getAllCandidates,
  approveCandidate,
  rejectCandidate,
} from '../../service.js/service';
import Preloader from '../../components/Preloader';

export default () => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCandidates();
  }, []);

  const fetchCandidates = async () => {
    try {
      setLoading(true);
      const data = await getAllCandidates();
      setCandidates(data);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card
      border="light"
      className="table-wrapper table-responsive shadow-sm p-2"
    >
      <Card.Body className="pt-0">
        <Table hover className="user-table align-items-center">
          <thead>
            <tr>
              <th className="border-bottom">Name</th>
              <th className="border-bottom">Position</th>
              <th className="border-bottom">College</th>
            </tr>
          </thead>
          {loading ? (
            <Preloader />
          ) : !candidates ? (
            <div className='text-center mt-5'>No candidates found!!!</div>
          ) : (
            <tbody>
              {candidates.map((candidate) => (
                <tr key={candidate.id}>
                  <td>{candidate.name}</td>
                  <td>{candidate.position}</td>
                  <td>{candidate.collage}</td>
                  {/* <td>{candidate.status}</td> */}
                </tr>
              ))}
            </tbody>
          )}
        </Table>
      </Card.Body>

    </Card>
  );
};
