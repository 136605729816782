import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faChartPie,
  faPeopleArrows,
  faTable,
} from '@fortawesome/free-solid-svg-icons';
import { Nav, Image, Button, Navbar } from '@themesberg/react-bootstrap';
import { Link } from 'react-router-dom';

import { Routes } from '../routes';

export default (props = {}) => {
  const logoUrl =
    'https://mouau.edu.ng/wp-content/uploads/2020/05/mouau_logo.jpg';
  const location = useLocation();
  const { pathname } = location;
  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText } = props;
    const classNames = badgeText
      ? 'd-flex justify-content-start align-items-center justify-content-between mt-2'
      : '';
    const navItemClassName = link === pathname ? 'active' : '';
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span>
            {icon ? (
              <span className="sidebar-icon">
                <FontAwesomeIcon icon={icon} />{' '}
              </span>
            ) : null}
            {image ? (
              <Image
                src={image}
                width={20}
                height={20}
                className="sidebar-icon svg-icon"
              />
            ) : null}

            <span className="sidebar-text">{title}</span>
          </span>
        </Nav.Link>
      </Nav.Item>
    );
  };

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className=" px-4 d-md-none"
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.DashboardOverview.path}
        >
          <Image src={logoUrl} className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar className={`collapse ${showClass} sidebar d-md-block`}>
          <div className="sidebar-inner px-4 pt-3">
            <Navbar.Brand
              className="me-lg-5 mb-5"
              as={Link}
              to={Routes.LandingPage.path}
            >
              <Image src={logoUrl} className="navbar-brand-light" />
            </Navbar.Brand>
            <Nav className="flex-column pt-3 pt-md-0 mt-5">
              <NavItem
                title="Overview"
                link={Routes.DashboardOverview.path}
                icon={faChartPie}
              />
              {/* <NavItem
                title="Ballot"
                link={Routes.Ballots.path}
                icon={faTable}
              />
              <NavItem
                title="Students"
                link={Routes.StudentsList.path}
                icon={faPeopleArrows}
              /> */}
              <NavItem
                title="Reports"
                link={Routes.Reports.path}
                icon={faChartBar}
              />
              <NavItem
                title="Votes"
                link={Routes.Votes.path}
                icon={faChartBar}
              />
              {/* <NavItem
                title="Candidates"
                link={Routes.CandidateLists.path}
                icon={faUsers}
              /> */}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
