import axiosService, { API_URL } from './axiosService';

// const API_URL = 'localhost:8080';

export const getOTP = async (regno) => {
  try {
    const response = await axiosService.post(`${API_URL}/otp`, {
      regno: regno,
    });
    return response.data.otp;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error while getting OTP');
  }
};

export const getCandidates = async (collage, position) => {
  try {
    const response = await axiosService.get(`${API_URL}/contestants`, {
      collage,
      position,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching candidates');
  }
};

export const getResult = async (collage, position) => {
  try {
    const response = await axiosService.get(`${API_URL}/result`, {
      collage,
      position,
    });
    return response.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching results');
  }
};

export const getWinners = async () => {
  try {
    const response = await axiosService.get(`${API_URL}/winners`);
    return response.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    // throw new Error('Error fetching winners');
  }
};

export const getVotes = async (collage, position, regno, page, limit) => {
  try {
    const response = await axiosService.get(`${API_URL}/vote-details`, {
      collage,
      position,
      regno,
      page,
      limit,
    });
    return response.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching winners');
  }
};

export const getPositions = async () => {
  try {
    const positions = await axiosService.get(`${API_URL}/positions`);
    return positions.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching positions');
  }
};

export const getCollages = async () => {
  try {
    const collages = await axiosService.get(`${API_URL}/collages`);
    return collages.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching collages');
  }
};

export const vote = async ({ position, collage, candidate }) => {
  // try {
  //   const collages = await axiosService.post(`${API_URL}/vote`, {
  //     position,
  //     collage,
  //     candidate,
  //   });
  //   return collages.data.data;
  // } catch (error) {
  //   console.error('Error:', error.message);
  //   throw new Error('Error fetching collages');
  // }
};

export const bulkVote = async (votes) => {
  // try {
  //   const response = await axiosService.post(`${API_URL}/bulk-vote`, votes);
  //   if (!response.data.success) {
  //     throw new Error(response.data.error);
  //   }
  //   return response.data.data;
  // } catch (error) {
  //   console.error('Error:', error.message);
  //   throw new Error('Error fetching votes');
  // }
};

export const fetchWinnersData = async () => {
  try {
    const response = await axiosService.get(`${API_URL}/results`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching winners data:', error);
    return [];
  }
};

export const getStudents = async () => {
  try {
    const response = await axiosService.get(`${API_URL}/students`);
    return response.data.data;
  } catch (error) {
    console.error('Error fetching students:', error);
    return [];
  }
};

export const registerCandidate = async (candidateData) => {
  try {
    const response = await axiosService.post(
      `${API_URL}/register-candidate`,
      candidateData
    );
    return response.data;
  } catch (error) {
    console.error('Error registering candidate:', error);
    throw new Error('Error while registering candidate');
  }
};

export const approveCandidate = async (candidateId) => {
  try {
    await axiosService.post(`${API_URL}/candidates/${candidateId}/approve`);
  } catch (error) {
    throw new Error('Error approving candidate');
  }
};

export const rejectCandidate = async (candidateId, reason) => {
  try {
    await axiosService.post(`${API_URL}/candidates/${candidateId}/reject`);
  } catch (error) {
    throw new Error('Error rejecting candidate');
  }
};

export const getAllCandidates = async () => {
  try {
    const candidates = await axiosService.get(`${API_URL}/all-candidates`);
    return candidates.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching candidates');
  }
};

export const getDepartments = async () => {
  try {
    const collages = await axiosService.get(`${API_URL}/collages`);
    return collages.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching collages');
  }
};

export const getStudentsCount = async () => {
  try {
    const collages = await axiosService.get(`${API_URL}/students-count`);
    return collages.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching students');
  }
};

export const getVotersCount = async () => {
  try {
    const collages = await axiosService.get(`${API_URL}/voters-count`);
    return collages.data.data;
  } catch (error) {
    console.error('Error:', error.message);
    throw new Error('Error fetching voters');
  }
};
