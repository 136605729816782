import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCheck,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Col,
  Row,
  Image,
  Button,
  Container,
  Navbar,
  Dropdown,
  Nav,
} from '@themesberg/react-bootstrap';
import { HashLink } from 'react-router-hash-link';

import ElectionCard from './components/ElectionCard';
import { useAuth } from '../contexts/AuthContext';

export default () => {
  const { user, logout } = useAuth();

  const handleLogout = () => {
    logout();
  };

  const logoUrl =
    'https://mouau.edu.ng/wp-content/uploads/2020/05/mouau_logo.jpg';

  // Function to get initials from the user's full name
  const getInitials = (fullName) => {
    const nameParts = fullName.split(' ');
    if (nameParts.length > 1) {
      return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
    }
    return nameParts[0][0].toUpperCase(); // Return first letter if no last name
  };

  return (
    <>
      <Navbar expand="lg" sticky="top">
        <Container
          className="d-flex justify-content-between"
          style={{ padding: '0 1rem' }}
        >
          <Navbar.Brand
            as={HashLink}
            to="/"
            className="d-flex align-items-center"
          >
            <Image src={logoUrl} style={{ height: '40px' }} />
          </Navbar.Brand>

          <div className="d-flex align-items-center">
            {user ? (
              <Dropdown drop="start">
                <Dropdown.Toggle variant="none">
                  <div className="d-flex align-items-center">
                    <div
                      style={{
                        width: '45px',
                        height: '45px',
                        borderRadius: '50%',
                        backgroundColor: '#22a924',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '1.25rem',
                        fontWeight: 'bold',
                        marginRight: '10px',
                      }}
                    >
                      {getInitials(user.name)}
                      <FontAwesomeIcon icon={faCaretDown} className="" />
                    </div>
                  </div>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item className="fw-bold">Vote</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <FontAwesomeIcon
                      icon={faSignOutAlt}
                      className="me-2 text-danger"
                    />
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              // <Button
              //   as={HashLink}
              //   to="/auth/sign-in"
              //   variant="outline-light"
              //   style={{ marginLeft: '1rem', color: '#000' }}
              // >
              //   Login
              // </Button>
              ''
            )}
          </div>
        </Container>
      </Navbar>

      <section
        className="section-header overflow-hidden pt-2 pt-lg-2pb-3 pb-lg-4 hero-bg text-white"
        id="home"
      >
        <Container className="mt-3">
          <Row className="mb-6">
            <Col xs={12} className="text-center">
              <div className="react-big-icon d-none d-lg-block">
                <span className="fab fa-react"></span>
              </div>
              <h1 className="fw-bolder white">
                Welcome to SUG E-Voting System
              </h1>
              <p className="text-muted white fw-light mb-5 h5">
                Empowering you with Secured and Convenient E-Voting Solutions
              </p>
              <div className="d-flex align-items-center justify-content-center">
                {/* {user?.voted === 'true' ? (
                  <p className="">Thank you for voting!</p>
                ) : (
                  <Button
                    as={HashLink}
                    to="/ballot"
                    variant="outline-white"
                    className="ms-3"
                  >
                    <FontAwesomeIcon icon={faCheck} className="me-1 ml-1" />{' '}
                    Vote Now
                  </Button>
                )} */}
                <p>
                  Thank you for your participation! The voting exercise has
                  officially concluded.
                </p>
              </div>
            </Col>
          </Row>
          <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
            <svg
              className="fill-soft"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 3000 185.4"
            >
              <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
            </svg>
          </figure>
        </Container>
      </section>
      <section>
        <Container>
          <ElectionCard />
        </Container>
      </section>
      {/* <footer className="footer py-6 ">
        <Container>
          <hr className="bg-gray my-5" />
          <Row>
            <Col className="mb-md-2">
              <Card.Link className="d-flex justify-content-center">
                <Image
                  src={logoUrl}
                  height={35}
                  className="d-block mx-auto mb-3"
                  alt="Themesberg Logo"
                />
              </Card.Link>
              <div
                className="d-flex text-center justify-content-center align-items-center"
                role="contentinfo"
              >
                <p className="font-weight-normal font-small mb-0">
                  Copyright © Themesberg 2019-
                  <span className="current-year">2021</span>. All rights
                  reserved.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </>
  );
};
