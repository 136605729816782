import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Button,
  Table,
  InputGroup,
  Form,
} from '@themesberg/react-bootstrap';
import { getCollages, getPositions, getVotes } from '../service.js/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

export default () => {
  const [reports, setReports] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [collage, setCollage] = useState('');
  const [position, setPosition] = useState('');
  const [collages, setCollages] = useState([]);
  const [positions, setPositions] = useState([]);
  const [maxPage, setMaxtPage] = useState(1);
  const [regNumber, setRegNumber] = useState('');

  const limit = 50;

  const SRC = 'STUDENTS REPRESENTATIVE COUNCIL (SRC)';
  const orderedCollages = [
    'CAERSE',
    'CEET',
    'COLNAS',
    'CASAP',
    'CVM',
    'COED',
    'CAFST',
    'COLMAS',
    'COLPAS',
    'CCSS',
    'CNREM',
  ];

  const orderedPositions = [
    { name: 'PRESIDENT', sortOrder: 0 },
    { name: 'VICE PRESIDENT', sortOrder: 1 },
    { name: 'SECRETARY GENERAL', sortOrder: 2 },
    { name: 'ASSISTANT SECRETARY GENERAL', sortOrder: 3 },
    { name: 'DIRECTOR OF WELFARE', sortOrder: 4 },
    { name: 'DIRECTOR OF SOCIALS', sortOrder: 5 },
    { name: 'DIRECTOR OF FINANCE', sortOrder: 6 },
    { name: 'DIRECTOR OF TREASURY', sortOrder: 7 },
    { name: 'PUBLIC RELATIONS OFFICER', sortOrder: 8 },
    { name: 'DIRECTOR OF TRANSPORT', sortOrder: 9 },
    { name: 'DIRECTOR OF SPORTS', sortOrder: 10 },
    { name: 'STUDENTS REPRESENTATIVE COUNCIL (SRC)', sortOrder: 11 },
  ];

  useEffect(() => {
    const fetchReports = async () => {
      const collageResult = await getCollages();

      const sortedCollages = orderedCollages.filter((c) =>
        collageResult.includes(c)
      );
      setCollages(sortedCollages);
      // const positionResult = await getPositions();
      setPositions(orderedPositions);

      if (!position) {
        setCollage(collageResult[0]);
        setPosition(orderedPositions[0].name);
      }

      const p = position ? position : orderedPositions[0].name;
      let c = collage ? collage : collageResult[0];

      loadResords(page, p, c);
    };
    fetchReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadResords = async (newPage, p, c) => {
    if (p !== SRC) {
      c = 'EXEC';
    }
    if (!newPage) newPage = page;
    const response = await getVotes(c, p, regNumber, newPage, limit);
    setReports(response.data);
    setTotal(response.total);

    let pages = Math.round(response.total / limit);
    if (pages * limit < response.total) pages += 1;
    setMaxtPage(pages);
  };

  const changePage = (newPage) => {
    setPage(newPage);
    loadResords(newPage, position, collage);
  };

  const onCollageSelected = (c) => {
    setCollage(c);
    loadResords(1, position, c);
  };

  const onPositionSelected = (p) => {
    setPosition(p);
    loadResords(1, p, collage);
  };

  return (
    <Card border="light" className="shadow-sm mt-5">
      <Card.Header>
        <Row className="align-items-center">
          <Col>{/* <h5>SUG Election Details {total}</h5> */}</Col>
          <Col className="text-end noprint">
            <Row>
              <Col>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={regNumber}
                    onChange={(e) => setRegNumber(e.target.value)}
                  />
                </InputGroup>
              </Col>
              <Col>
                <select
                  onChange={(el) => onPositionSelected(el.target.value)}
                  value={position}
                >
                  <option></option>
                  {positions.map((c) => (
                    <option key={c.name}>{c.name}</option>
                  ))}
                </select>
              </Col>
              {position === SRC && (
                <Col>
                  <select
                    onChange={(el) => onCollageSelected(el.target.value)}
                    value={collage}
                  >
                    <option></option>
                    {collages.map((c) => (
                      <option key={c}>{c}</option>
                    ))}
                  </select>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead>
          <tr>
            <th scope="col">Student</th>
            <th scope="col">Candidate</th>
          </tr>
        </thead>
        <tbody>
          {reports &&
            reports.map((pv) => (
              <tr key={pv.id}>
                <td>{pv.student}</td>
                <td>{pv.candidate}</td>
              </tr>
            ))}
        </tbody>
      </Table>
      <Card.Footer>
        <Row>
          <Col>
            {page > 1 && (
              <Button onClick={() => changePage(page - 1)}>Previous</Button>
            )}
          </Col>
          <Col>
            {page < maxPage && (
              <Button onClick={() => changePage(page + 1)}>Next</Button>
            )}
          </Col>
        </Row>
      </Card.Footer>
    </Card>
  );
};
