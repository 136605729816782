import React from 'react';
import { Row } from '@themesberg/react-bootstrap';

import ElectionCard from '../components/ElectionCard';
export default () => {
  return (
    <>
      <Row className="mt-5">
        <ElectionCard />
      </Row>
    </>
  );
};
