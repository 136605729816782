import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';

// core styles
import './scss/volt.scss';

// vendor styles
import 'react-datetime/css/react-datetime.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import HomePage from './pages/HomePage';
import ScrollToTop from './components/ScrollToTop';
import { AuthProvider } from './contexts/AuthContext';

ReactDOM.render(
  <HashRouter>
    <AuthProvider>
      <ScrollToTop />
      <HomePage />
      <ToastContainer />
    </AuthProvider>
  </HashRouter>,
  document.getElementById('root')
);
