import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Routes } from '../routes';

// pages
import LandingPage from './LandingPage';
import Reports from './Reports';
import Votes from './Votes';
import DashboardOverview from './dashboard/DashboardOverview';
import StudentsList from './Students';
import Settings from './Settings';
import Ballots from './ballots/Ballots';
import Signin from './examples/Signin';
import Ballot from './examples/Ballot';
import ForgotPassword from './examples/ForgotPassword';
import ResetPassword from './examples/ResetPassword';
import Lock from './examples/Lock';
import NotFoundPage from './examples/NotFound';
import ServerError from './examples/ServerError';

// components
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Preloader from '../components/Preloader';

import Accordion from './components/Accordion';
import Alerts from './components/Alerts';
import Badges from './components/Badges';
import Breadcrumbs from './components/Breadcrumbs';
import Buttons from './components/Buttons';
import Forms from './components/Forms';
import Modals from './components/Modals';
import Navs from './components/Navs';
import Navbars from './components/Navbars';
import Pagination from './components/Pagination';
// import Popovers from './components/Popovers';
import Progress from './components/Progress';
import Tables from './components/Tables';
import Tabs from './components/Tabs';
import Tooltips from './components/Tooltips';
import Toasts from './components/Toasts';
import CandidateRegistration from './candidates/CandidateRegistration';
import CandidateLists from './candidates/CandidateLists';

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {' '}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{' '}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Preloader show={loaded ? false : true} />
          <Sidebar />

          <main className='content'>
            <Navbar />
            <Component {...props} />
          </main>
        </>
      )}
    />
  );
};

export default () => (
  <Switch>
    <RouteWithLoader
      exact
      path={Routes.LandingPage.path}
      component={LandingPage}
    />
    <RouteWithLoader exact path={Routes.Signin.path} component={Signin} />
    <RouteWithLoader exact path={Routes.CandidateRegistration.path} component={CandidateRegistration} />
    <RouteWithLoader exact path={Routes.Ballot.path} component={Ballot} />
    <RouteWithLoader
      exact
      path={Routes.ForgotPassword.path}
      component={ForgotPassword}
    />
    <RouteWithLoader
      exact
      path={Routes.ResetPassword.path}
      component={ResetPassword}
    />
    <RouteWithLoader exact path={Routes.Lock.path} component={Lock} />
    <RouteWithLoader
      exact
      path={Routes.NotFound.path}
      component={NotFoundPage}
    />
    <RouteWithLoader
      exact
      path={Routes.ServerError.path}
      component={ServerError}
    />

    {/* pages */}
    <RouteWithSidebar
      exact
      path={Routes.DashboardOverview.path}
      component={DashboardOverview}
    />
    <RouteWithSidebar exact path={Routes.Reports.path} component={Reports} />
    <RouteWithSidebar exact path={Routes.Votes.path} component={Votes} />
    <RouteWithSidebar
      exact
      path={Routes.StudentsList.path}
      component={StudentsList}
    />
    <RouteWithSidebar exact path={Routes.Settings.path} component={Settings} />
    <RouteWithSidebar exact path={Routes.Ballots.path} component={Ballots} />
    <RouteWithSidebar exact path={Routes.CandidateLists.path} component={CandidateLists} />

    {/* components */}
    <RouteWithSidebar
      exact
      path={Routes.Accordions.path}
      component={Accordion}
    />
    <RouteWithSidebar exact path={Routes.Alerts.path} component={Alerts} />
    <RouteWithSidebar exact path={Routes.Badges.path} component={Badges} />
    <RouteWithSidebar
      exact
      path={Routes.Breadcrumbs.path}
      component={Breadcrumbs}
    />
    <RouteWithSidebar exact path={Routes.Buttons.path} component={Buttons} />
    <RouteWithSidebar exact path={Routes.Forms.path} component={Forms} />
    <RouteWithSidebar exact path={Routes.Modals.path} component={Modals} />
    <RouteWithSidebar exact path={Routes.Navs.path} component={Navs} />
    <RouteWithSidebar exact path={Routes.Navbars.path} component={Navbars} />
    <RouteWithSidebar
      exact
      path={Routes.Pagination.path}
      component={Pagination}
    />
    {/* <RouteWithSidebar exact path={Routes.Popovers.path} component={Popovers} /> */}
    <RouteWithSidebar exact path={Routes.Progress.path} component={Progress} />
    <RouteWithSidebar exact path={Routes.Tables.path} component={Tables} />
    <RouteWithSidebar exact path={Routes.Tabs.path} component={Tabs} />
    <RouteWithSidebar exact path={Routes.Tooltips.path} component={Tooltips} />
    <RouteWithSidebar exact path={Routes.Toasts.path} component={Toasts} />

    <Redirect to={Routes.NotFound.path} />
  </Switch>
);
