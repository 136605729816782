import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import { useAuth } from '../../contexts/AuthContext';

import { getOTP } from '../../service.js/service';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default () => {
  const { login, user } = useAuth();

  const [regno, setregno] = useState('');
  const [otp, setOtp] = useState('');
  const [showOtpField, setShowOtpField] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      document.location.href = '/';
    }
  }, [user]);

  const handleregNumberChange = (event) => {
    setregno(event.target.value);
  };

  const handleSendOtp = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const receivedOtp = await getOTP(regno);
      setOtp(receivedOtp);
      setShowOtpField(true);
      toast.success(
        'OTP has been sent to your registered email and or phone number',
        {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
      setLoading(false);
    } catch (error) {
      console.error('Error:', error.message);
      toast.error(
        error.message ? error.message : 'Error in getting OTP pls try again',
        {
          position: 'top-right',
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      await login(regno, otp);

      toast.success('Login Successfull', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      // history.push('/');
      document.location.href = '/';
    } catch (error) {
      console.error('Error:', error.message);

      if (error.response) {
        toast.error(
          error.response.data.error || 'An error occurred during login.',
          {
            position: 'top-right',
            autoClose: 4000,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 mb-4 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in</h3>
                </div>
                <Form.Group id="text" className="mb-4">
                  <Form.Label>Reg Number</Form.Label>
                  <Form.Control
                    autoFocus
                    required
                    type="text"
                    placeholder="Enter your reg number"
                    onChange={handleregNumberChange}
                  />
                </Form.Group>
                {showOtpField ? (
                  <>
                    <Form.Group id="otp" className="mb-4">
                      <Form.Label>OTP</Form.Label>
                      <Form.Control
                        autoFocus
                        required
                        type="text"
                        placeholder="Enter OTP "
                        value={otp}
                        onChange={handleOtpChange}
                      />
                    </Form.Group>

                    <Button
                      variant="primary"
                      type="submit"
                      onClick={handleLogin}
                      className="w-100"
                    >
                      Login
                    </Button>

                    <p className="mt-3">
                      Didn't get OTP? Click{' '}
                      <a
                        href="https://t.me/umudyke_decide_bot"
                        style={{
                          textDecoration: 'none',
                          color: '#22A924',
                          fontWeight: 600,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        here
                      </a>{' '}
                      to get it on telegram.
                    </p>
                    <p>
                      Make sure your phone number in the database(student data)
                      is linked to your telegram account. <br />
                      <span className='text-muted'>
                        Note: You can create a new telegram account with the phone number
                        (
                        <a
                          href='https://telegram.org/ 
                        target="_blank"
                        rel="noopener noreferrer"'
                        >
                          https://telegram.org/
                        </a>
                        ).
                      </span>
                    </p>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleSendOtp}
                    className="w-100"
                  >
                    Get OTP
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
