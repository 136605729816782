import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Table,
  InputGroup,
  Form,
} from '@themesberg/react-bootstrap';
import { getStudents } from '../service.js/service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { CustomPagination } from './components/Paginate';

export default () => {
  const [students, setStudents] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10; // Number of students per page

  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = students.slice(
    indexOfFirstStudent,
    indexOfLastStudent
  );

  useEffect(() => {
    const fetchStudents = async () => {
      const response = await getStudents();
      setStudents(response);
    };
    fetchStudents();
  }, []);

  // Filter customers based on search term
  useEffect(() => {
    const filtered = students?.filter((student) => {
      const fullName = ` ${student.name}`.toLowerCase();
      const regNumber = student.regNumber.toString();
      return (
        fullName.includes(searchTerm.toLowerCase()) ||
        regNumber.includes(searchTerm)
      );
    });
    setFilteredStudents(filtered);
  }, [searchTerm, students]);


  const TableRow = (props) => {
    const { regno, name, faculty } = props;

    return (
      <tr>
        <th>{regno}</th>
        <td>{name}</td>
        <td>{faculty}</td>
      </tr>
    );
  };

  return (
    <Card border="light" className="shadow-sm mt-5 p-2">
      <Card.Header>
        <div className="table-settings mb-4">
          <Row className="justify-content-between align-items-center">
            <Col xs={8} md={6} lg={3} xl={4}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
      </Card.Header>
      <Table responsive className="align-items-center table-flush">
        <thead>
          <tr>
            <th scope="col">Reg Number</th>
            <th scope="col">Name</th>
            <th scope="col">Faculty</th>
          </tr>
        </thead>
        {filteredStudents.length === 0 ? (
          <p className=" mt-5">No student data available at the moment.</p>
        ) : (
          <tbody>
            {filteredStudents.map((pv) => (
              <TableRow key={`page-visit-${pv.id}`} {...pv} />
            ))}
          </tbody>
        )}
      </Table>
      <CustomPagination
        totalPages={Math.ceil(students.length / studentsPerPage)}
        activeItem={currentPage}
        size="md"
        withIcons={true}
        onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
      />
    </Card>
  );
};
