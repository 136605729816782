import React from 'react';
import { Card } from '@themesberg/react-bootstrap';
import CandidateCard from '../components/CandidateCard';

export default () => {
  return (
    <Card border="light" className="shadow-sm mt-5 p-2">
      <CandidateCard />
    </Card>
  );
};
