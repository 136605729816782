import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, Container } from '@themesberg/react-bootstrap';
import { toast } from 'react-toastify';
import {
  getCollages,
  getPositions,
  registerCandidate,
} from '../../service.js/service';
import { useAuth } from '../../contexts/AuthContext';
import Preloader from '../../components/Preloader';

export default () => {
  const { user } = useAuth();

  const [name, setName] = useState('');
  const [picture, setPicture] = useState(null);
  const [preview, setPreview] = useState('');
  const [selectedPosition, setSelectedPosition] = useState('');
  const [positionOptions, setPositionOptions] = useState([]);
  const [selectedCollege, setSelectedCollege] = useState('');
  const [collegeOptions, setCollegeOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Fetch positions and colleges on component mount
    const fetchOptions = async () => {
      try {
        setLoading(true);
        const positions = await getPositions();

        setPositionOptions(positions || []);

        const colleges = await getCollages();
        
        setCollegeOptions(colleges || []);

        if (user && user.college) {
          setSelectedCollege(user.college);
        } else if (colleges && colleges.length > 0) {
          setSelectedCollege(colleges[0]);
        }

        if (positions && positions.length > 0) {
          setSelectedPosition(positions[0]);
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOptions();
  }, [user]);

  // Handles name input change
  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  // Handles position change
  const handlePositionChange = (event) => {
    setSelectedPosition(event.target.value);
  };

  // Handles college change
  const handleCollegeChange = (event) => {
    setSelectedCollege(event.target.value);
  };

  // Handles picture upload, previews the image, and converts it to base64
  const handlePictureUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
        setPicture(reader.result.split(',')[1]);
      };
      reader.readAsDataURL(file);
    }
  };

  // Handles form submission
  const handleRegisterCandidate = async (event) => {
    event.preventDefault();
    setLoading(true);

    const candidateData = {
      name,
      college: selectedCollege,
      position: selectedPosition,
      picture,
    };

    try {
      await registerCandidate(candidateData);

      toast.success('Registration successful', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.error('Error:', error.message);
      toast.error('Error in registration, please try again', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center form-bg-image">
            <Col
              xs={12}
              className="d-flex align-items-center justify-content-center"
            >
              {loading ? (
                <Preloader />
              ) : (
                <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 mb-4 fmxw-500">
                  <div className="text-center text-md-center mb-4 mt-md-0">
                    <h3 className="mb-0">Candidate Registration</h3>
                  </div>
                  <Form.Group id="name" className="mb-4">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      onChange={handleNameChange}
                    />
                  </Form.Group>
                  <Form.Group id="position" className="mb-4">
                    <Form.Label>Select Position</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      value={selectedPosition}
                      onChange={handlePositionChange}
                    >
                      <option value="">Select the position</option>
                      {positionOptions.map((position) => (
                        <option key={position.name} value={position}>
                          {position.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group id="college" className="mb-4">
                    <Form.Label>Select College</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      value={selectedCollege}
                      onChange={handleCollegeChange}
                    >
                      <option value="">Select your college</option>
                      {collegeOptions.map((college) => (
                        <option key={college} value={college}>
                          {college}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group id="picture" className="mb-4">
                    <Form.Label>Upload Picture</Form.Label>
                    <Form.Control
                      required
                      type="file"
                      accept="image/*"
                      onChange={handlePictureUpload}
                    />
                  </Form.Group>
                  {preview && (
                    <div className="mb-4">
                      <img
                        src={preview}
                        alt="Preview"
                        style={{ width: '100%', height: 'auto' }}
                      />
                    </div>
                  )}
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-100"
                    disabled={loading}
                    onClick={handleRegisterCandidate}
                  >
                    {loading ? 'Registering...' : 'Register'}
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
