export const Routes = {
  // pages
  LandingPage: { path: '/' },
  DashboardOverview: { path: '/dashboard/overview' },
  StudentsList: { path: '/students' },
  Settings: { path: '/settings' },
  Reports: { path: '/reports' },
  Votes: { path: '/votes' },
  Ballots: { path: '/ballots/general' },
  Billing: { path: '/examples/billing' },
  Invoice: { path: '/examples/invoice' },
  Signin: { path: '/auth/sign-in' },
  Ballot: { path: '/ballot' },
  ForgotPassword: { path: '/examples/forgot-password' },
  ResetPassword: { path: '/examples/reset-password' },
  Lock: { path: '/examples/lock' },
  NotFound: { path: '/404' },
  ServerError: { path: '/examples/500' },
  General: { path: '/ballots/general' },
  CandidateRegistration: { path: '/candidates/register' },
  CandidateLists: { path: '/candidates' },

  // components
  Accordions: { path: '/components/accordions' },
  Alerts: { path: '/components/alerts' },
  Badges: { path: '/components/badges' },
  Widgets: { path: '/widgets' },
  Breadcrumbs: { path: '/components/breadcrumbs' },
  Buttons: { path: '/components/buttons' },
  Forms: { path: '/components/forms' },
  Modals: { path: '/components/modals' },
  Navs: { path: '/components/navs' },
  Navbars: { path: '/components/navbars' },
  Pagination: { path: '/components/pagination' },
  Popovers: { path: '/components/popovers' },
  Progress: { path: '/components/progress' },
  Tables: { path: '/components/tables' },
  Tabs: { path: '/components/tabs' },
  Tooltips: { path: '/components/tooltips' },
  Toasts: { path: '/components/toasts' },
  WidgetsComponent: { path: '/components/widgets' },
};
