/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  ProgressBar,
  Card,
  Dropdown,
} from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import { getCollages, getResult } from '../../service.js/service';
import { useAuth } from '../../contexts/AuthContext';
import Preloader from '../../components/Preloader';

const orderedPositions = [
  { name: 'PRESIDENT', sortOrder: 0 },
  { name: 'VICE PRESIDENT', sortOrder: 1 },
  { name: 'SECRETARY GENERAL', sortOrder: 2 },
  { name: 'ASSISTANT SECRETARY GENERAL', sortOrder: 3 },
  { name: 'DIRECTOR OF WELFARE', sortOrder: 4 },
  { name: 'DIRECTOR OF SOCIALS', sortOrder: 5 },
  { name: 'DIRECTOR OF FINANCE', sortOrder: 6 },
  { name: 'DIRECTOR OF TREASURY', sortOrder: 7 },
  { name: 'PUBLIC RELATIONS OFFICER', sortOrder: 8 },
  { name: 'DIRECTOR OF TRANSPORT', sortOrder: 9 },
  { name: 'DIRECTOR OF SPORTS', sortOrder: 10 },
  { name: 'STUDENTS REPRESENTATIVE COUNCIL (SRC)', sortOrder: 11 },
];

export default () => {
  const { user } = useAuth();
  const [selectedPosition, setSelectedPosition] = useState('Position');
  const [filteredCandidates, setCandidates] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [collage, setCollage] = useState(user?.collage || 'College');
  const [collages, setCollages] = useState([]);
  const [loading, setLoading] = useState(false);

  const orderedCollages = [
    'CAERSE',
    'CEET',
    'COLNAS',
    'CASAP',
    'CVM',
    'COED',
    'CAFST',
    'COLMAS',
    'COLPAS',
    'CCSS',
    'CNREM',
  ];

  const fetchCandidates = async (position, collage) => {
    try {
      setLoading(true);
      if (position !== 'STUDENTS REPRESENTATIVE COUNCIL (SRC)') {
        collage = 'EXEC';
      }
      const candidatesData = await getResult(
        collage || 'EXEC',
        position || 'PRESIDENT'
      );
      setCandidates(candidatesData || []);
      // TODO: fetch dept positions
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCollage = async () => {
    try {
      setPositionOptions(orderedPositions || []);

      const collages = await getCollages();

      const sortedCollages = orderedCollages.filter((c) =>
        collages.includes(c)
      );
      setCollages(sortedCollages);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCandidates();
    fetchCollage();
  }, []);

  const numOfVotes = () => {
    if (!filteredCandidates) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < filteredCandidates.length; i++) {
      total += filteredCandidates[i].votecount;
    }
    return total;
  };

  const onPositionChanged = (position) => {
    setSelectedPosition(position);
    fetchCandidates(position, collage);
  };

  const onCollageSelected = (c) => {
    setCollage(c);
    fetchCandidates(selectedPosition, c);
  };

  const Progress = (props) => {
    const { title, percentage, icon, color, last = false, votes } = props;
    const extraClassName = last ? '' : 'mb-2';

    return (
      <Row className={`align-items-center ${extraClassName}`}>
        <Col xs="auto">
          <span className={`icon icon-md text-${color}`}>
            <img
              src={icon}
              alt="candidate"
              className="rounded"
              style={{ width: `50px` }}
            />
          </span>
        </Col>
        <Col>
          <div className="progress-wrapper">
            <div className="progress-info">
              <h6 className="mb-0">{title}</h6>

              <small className="fw-bold text-dark">
                <span>
                  {percentage}% ({votes} votes)
                </span>
              </small>
            </div>
            <ProgressBar variant={color} now={percentage} min={0} max={100} />
          </div>
        </Col>
        <hr className="bg-gray my-3" />
      </Row>
    );
  };

  return (
    <>
      <Row className="mt-5">
        <Card border="light" className="shadow-sm">
          <Card.Header className="border-bottom border-light d-flex justify-content-between">
            <h5 style={{ fontSize: '16px', fontWeight: 700 }} className="mb-0">
              Live Results
            </h5>
            <>
              <div className="d-flex">
                <div>
                  <Dropdown>
                    <Dropdown.Toggle variant="outline-primary" size="sm">
                      {collage}
                      <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {collages?.map((c) => (
                        <Dropdown.Item
                          key={c}
                          onClick={() => onCollageSelected(c)}
                        >
                          {c}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div>&nbsp;&nbsp;</div>

                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary"
                      size="sm"
                      style={{
                        width: '150px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {selectedPosition}
                      <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {positionOptions?.map((position) => (
                        <Dropdown.Item
                          key={position.name}
                          onClick={() => onPositionChanged(position.name)}
                        >
                          {position.name}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          </Card.Header>
          <Card.Body className="mt-5">
            {loading ? (
              <Preloader />
            ) : (
              filteredCandidates &&
              filteredCandidates.map((candidate, i) => (
                <Progress
                  key={candidate.id}
                  title={
                    <span style={{ fontSize: '14px', fontWeight: 700 }}>
                      {candidate.name}
                    </span>
                  }
                  color={
                    (candidate.position === 'EXEC' && i === 0) ||
                    (candidate.position !== 'EXEC' && i < 5)
                      ? 'success'
                      : 'purple'
                  }
                  icon={candidate.filename}
                  percentage={
                    candidate.votecount
                      ? ((candidate.votecount / numOfVotes()) * 100).toFixed(2)
                      : 0
                  }
                  votes={candidate.votecount}
                />
              ))
            )}
          </Card.Body>
        </Card>
      </Row>
    </>
  );
};
