/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Dropdown, Button } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCaretDown,
  faCheck,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';

import { getCandidates, getCollages } from '../../service.js/service';
import { useAuth } from '../../contexts/AuthContext';
import ConfirmVotesModal from './ConfirmVotesModal';
import Preloader from '../../components/Preloader';
import CandidatePreviewModal from './CandidatePreviewModal';

const orderedPositions = [
  { name: 'PRESIDENT', sortOrder: 0 },
  { name: 'VICE PRESIDENT', sortOrder: 1 },
  { name: 'SECRETARY GENERAL', sortOrder: 2 },
  { name: 'ASSISTANT SECRETARY GENERAL', sortOrder: 3 },
  { name: 'DIRECTOR OF WELFARE', sortOrder: 4 },
  { name: 'DIRECTOR OF SOCIALS', sortOrder: 5 },
  { name: 'DIRECTOR OF FINANCE', sortOrder: 6 },
  { name: 'DIRECTOR OF TREASURY', sortOrder: 7 },
  { name: 'PUBLIC RELATIONS OFFICER', sortOrder: 8 },
  { name: 'DIRECTOR OF TRANSPORT', sortOrder: 9 },
  { name: 'DIRECTOR OF SPORTS', sortOrder: 10 },
  { name: 'STUDENTS REPRESENTATIVE COUNCIL (SRC)', sortOrder: 11 },
];

export default () => {
  const { addVote, votes, user } = useAuth();

  const [selectedPosition, setSelectedPosition] = useState('Position');
  const [filteredCandidates, setCandidates] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [collage, setCollage] = useState(user?.collage);
  const [collages, setCollages] = useState([]);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const orderedCollages = [
    'CAERSE',
    'CEET',
    'COLNAS',
    'CASAP',
    'CVM',
    'COED',
    'CAFST',
    'COLMAS',
    'COLPAS',
    'CCSS',
    'CNREM',
  ];

  const fetchCandidates = async (position, collage) => {
    try {
      setLoading(true);
      if (position !== 'STUDENTS REPRESENTATIVE COUNCIL (SRC)') {
        collage = 'EXEC';
      }
      const candidatesData = await getCandidates(
        collage || 'EXEC',
        position || 'PRESIDENT'
      );
      setCandidates(candidatesData || []);

      setPositionOptions(orderedPositions || []);

      const collages = await getCollages();
      const sortedCollages = orderedCollages.filter((c) =>
        collages.includes(c)
      );
      setCollages(sortedCollages);

      if (user) setCollage(user.collage);
    } catch (error) {
      console.error('Error fetching candidates:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidates();
  }, []);

  const onPositionChanged = (position) => {
    setSelectedPosition(position);
    fetchCandidates(position, collage);
  };

  const onCollageSelected = (c) => {
    setCollage(c);
    fetchCandidates(selectedPosition, c);
  };

  const isVotedFor = (candidateId) => {
    if (!votes || votes.length === 0) return false;
    for (let i = 0; i < votes.length; i++) {
      if (votes[i].id === candidateId) return true;
    }

    return false;
  };

  const openPreviewModal = (candidate) => {
    setSelectedCandidate(candidate);
    setShowPreviewModal(true);
  };

  return (
    <>
      <div className="candidates mt-5">
        <Row>
          <Col className="d-flex justify-content-between">
            <div className="c-head mb-5">
              <h3 style={{ fontSize: '16px', fontWeight: 700 }}>Candidates</h3>
            </div>
            <div className="d-flex">
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" size="sm">
                    {collage}
                    {/* <FontAwesomeIcon icon={faCaretDown} className="ms-2" /> */}
                  </Dropdown.Toggle>
                  {/* <Dropdown.Menu>
                    {collages &&
                      collages.map((c) => (
                        <Dropdown.Item
                          key={c}
                          onClick={() => onCollageSelected(c)}
                        >
                          {c}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>

              <div>&nbsp;&nbsp;</div>

              <div>
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="sm"
                    style={{
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {selectedPosition}
                    <FontAwesomeIcon icon={faCaretDown} className="ms-2" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {orderedPositions &&
                      orderedPositions.map((position) => (
                        <Dropdown.Item
                          key={position.name}
                          onClick={() => onPositionChanged(position.name)}
                        >
                          {position.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          {loading ? (
            <Preloader />
          ) : (
            filteredCandidates &&
            filteredCandidates?.map((candidate) => (
              <Col
                key={candidate.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="mb-4"
              >
                <Card border="light" className="text-center p-0 mb-4">
                  <div
                    style={{ backgroundImage: `url(${candidate.filename})` }}
                    className="profile-cover rounded-top"
                    onClick={() => openPreviewModal(candidate)} // Open the modal on image click
                  />
                  <Card.Body className="pb-5">
                    <Card.Title>{candidate.name}</Card.Title>
                    <Card.Subtitle className="fw-normal">
                      {candidate.department} {candidate.faculty}
                    </Card.Subtitle>
                    <Card.Text className="text-gray mb-4">
                      {candidate.position}
                    </Card.Text>

                    {user?.voted === 'true' && (
                      <Button
                        variant={'outline-success'}
                        className="ms-3"
                        disabled
                      >
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="me-1 ml-1"
                        />{' '}
                        Voted
                      </Button>
                    )}

                    {/* {!isVotedFor(candidate.id) && (
                      <Button
                        variant={'outline-danger'}
                        className="ms-3"
                        onClick={() => {
                          addVote(candidate);
                        }}
                      >
                        <FontAwesomeIcon icon={faCheck} className="me-1 ml-1" />{' '}
                        Vote
                      </Button>
                    )}

                    {isVotedFor(candidate.id) && (
                      <Button
                        variant={'outline-success'}
                        className="ms-3"
                        onClick={() => {
                          addVote(candidate);
                        }}
                        disabled
                      >
                        <FontAwesomeIcon icon={faCheck} className="me-1 ml-1" />{' '}
                        Vote
                      </Button>
                    )} */}
                  </Card.Body>
                </Card>
              </Col>
            ))
          )}
        </Row>

        <Row className="mb-5">
          <Col>
            <Button
              onClick={() => {
                setShowConfirmModal(true);
              }}
            >
              Preview
            </Button>
          </Col>
        </Row>
      </div>

      {showConfirmModal && (
        <ConfirmVotesModal
          onClose={() => setShowConfirmModal(false)}
          showModal={showConfirmModal}
          positions={positionOptions}
        />
      )}

      {showPreviewModal && (
        <CandidatePreviewModal
          show={showPreviewModal}
          onHide={() => setShowPreviewModal(false)}
          candidate={selectedCandidate}
        />
      )}
    </>
  );
};
