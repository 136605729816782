import React from 'react';
import { Modal, Button } from '@themesberg/react-bootstrap';

const CandidatePreviewModal = ({ show, onHide, candidate }) => {
  return (
    <Modal show={show} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>{candidate?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {candidate?.filename ? (
          <img
            src={candidate.filename}
            alt={candidate.name}
            className="img-fluid"
            style={{ width: '90%' }}
          />
        ) : (
          <p>No image available</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CandidatePreviewModal;
