import React, { useEffect, useState } from 'react';
import { Col, Row, Card, Button, Table } from '@themesberg/react-bootstrap';
import {
  getStudentsCount,
  getVotersCount,
  getWinners,
} from '../service.js/service';
import './print.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faUsers, faVoteYea } from '@fortawesome/free-solid-svg-icons';

export default () => {
  const [reports, setReports] = useState([]);
  const [studentsCount, setStudentsCount] = useState(0);
  const [votersCount, setVotersCount] = useState(0);

  useEffect(() => {
    const fetchReports = async () => {
      const response = await getWinners();
      setReports(response);
    };
    fetchReports();
  }, []);

  useEffect(() => {
    const fetchSumary = async () => {
      try {
        const totalVoters = await getStudentsCount();
        const totalVotes = await getVotersCount();

        setStudentsCount(totalVoters);
        setVotersCount(totalVotes);
      } catch (error) {}
    };
    fetchSumary();
  }, []);

  const SummaryCard = ({ icon, title, value }) => (
    <Card className="mb-3">
      <Card.Body className="d-flex align-items-center">
        <FontAwesomeIcon icon={icon} size="2x" className="me-3" />
        <div>
          <h6 className="mb-0">{title}</h6>
          <h3 className="fw-bold">{value}</h3>
        </div>
      </Card.Body>
    </Card>
  );

  return (
    <Card border="light" className="shadow-sm mt-5">
      <Card.Header>
        <Row className="align-items-center">
          <Col>
            <h5>SUG Election Winners</h5>
          </Col>
          <Col className="text-end noprint">
            <Button variant="success" onClick={window.print}>
              Print
            </Button>
          </Col>
        </Row>
      </Card.Header>

      <Card.Body>
        <Row>
          <Col xs={12} md={6} lg={3}>
            <SummaryCard
              icon={faUsers}
              title="Total number of Eligible Voters"
              value={studentsCount}
            />
          </Col>
          <Col xs={12} md={6} lg={3}>
            <SummaryCard
              icon={faVoteYea}
              title="Total Votes Cast"
              value={votersCount}
            />
          </Col>
        </Row>
      </Card.Body>
      <Table responsive className="align-items-center table-flush">
        <thead>
          <tr>
            <th scope="col">Position</th>
            <th scope="col">Winner</th>
            <th scope="col">Votes Recieved</th>
          </tr>
        </thead>
        {reports?.length === 0 ? (
          <p className="text-center mt-5">No winners data available.</p>
        ) : (
          <tbody>
            {reports.map((pv) => (
              <tr>
                <td>{pv.position}</td>
                <td>{pv.winner}</td>
                <td>
                  {pv.voteCount}({pv.dominance.toFixed(2)}%)
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </Table>
    </Card>
  );
};
