import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_URL } from '../service.js/axiosService';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [votes, setVotes] = useState([]);

  useEffect(() => {
    try {
      setUser(JSON.parse(localStorage.getItem('ACCESS_USER')));
    } catch (error) {
      console.error(error);
    }
  }, [setUser]);

  const login = async (regno, otp) => {
    try {
      const response = await axios.post(`${API_URL}/login`, {
        regno,
        otp,
      });
      const accessToken = response.data.data.token;
      // Store the JWT token in session storage
      localStorage.setItem('ACCESS_TOKEN_KEY', accessToken);
      // Now, set the access token as the default authorization header for all axios requests
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
      setUser(response.data.data);
      localStorage.setItem('ACCESS_USER', JSON.stringify(response.data.data));
      // history.push('/');
      document.location.href = '/';
    } catch (error) {
      throw new Error(error.response?.data?.error || 'An error occurred');
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('ACCESS_TOKEN_KEY');
    localStorage.removeItem('ACCESS_USER');
    history.push('/');
  };

  const addVote = (candidate) => {
    const existingVotesCount = votes.filter(
      (v) => v.position === candidate.position
    );
    if (
      candidate.position === 'STUDENTS REPRESENTATIVE COUNCIL (SRC)' &&
      existingVotesCount.length >= 5
    ) {
      toast.error('You can only vote 5 representatives', {
        position: 'top-right',
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    let filteredVotes = votes;
    if (
      candidate.position !== 'STUDENTS REPRESENTATIVE COUNCIL (SRC)' &&
      existingVotesCount.length >= 1
    ) {
      filteredVotes = votes.filter((v) => v.position !== candidate.position);

      setVotes([...filteredVotes, candidate]);
      toast.success('Vote Changed');
    } else {
      setVotes([...filteredVotes, candidate]);
      toast.success('Vote added');
    }
  };

  const removeVote = (position, candidate) => {
    const votesCopy = votes.filter(
      (v) => !(v.position === position && v.candidate === candidate)
    );
    setVotes(votesCopy);
  };

  const isVotedForCandidate = (candidateId) => {
    if (!votes || votes.length === 0) return false;
    for (let i = 0; i < votes.length; i++) {
      if (votes[i].id === candidateId) return true;
    }

    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        addVote,
        removeVote,
        user,
        login,
        logout,
        votes,
        setVotes,
        isVotedForCandidate,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
